import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorPage from './components/pages/ErrorPage';
import Main from './components/pages/Main';
import { AppProvider } from './components/context/appContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#047a9c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffb81c',
      contrastText: '#000',
    },
    tertiary: {
      main: '#D4EAE4',
      contrastText: '#000',
    },
    background: {
      default: '#F5F5F5',
    },
  },
  zIndex: {
    drawer: 5,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '160px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: '40px', // Set the default input height
          '& input': {
            padding: '10px 14px', // Adjust the input padding
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        size: 'small', // Default size
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '& .MuiIconButton-root': {
            size: 'large', // Consistent icon size
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      md: 960, // Medium devices (tablets)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});

const App = ({ authorized, instance, errMsg, invalidToken }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AppProvider>
            <CssBaseline />
            {authorized ? (
              <BrowserRouter>
                <Main instance={instance} />
              </BrowserRouter>
            ) : (
              <ErrorPage instance={instance} invalidToken={invalidToken} errMsg={errMsg} />
            )}
          </AppProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

App.propTypes = {
  authorized: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
  invalidToken: PropTypes.bool,
  errMsg: PropTypes.string,
};

export default App;
