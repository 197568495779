import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../images/logo.svg';
import { signOut } from '../../utils/authentication';

const Banner = (props) => {
  const onSignOut = () => {
    signOut(props.instance);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        color: '#000',
        backgroundColor: '#fff',
        padding: '2px 0',
        boxShadow: 'none',
      }}>
      <Toolbar variant="dense">
        <img src={logo} alt="pearson logo" />
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            marginLeft: 1,
          }}>
          Pearson Support Dashboard
        </Typography>
        <Button color="primary" onClick={onSignOut}>
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Banner.propTypes = {
  instance: PropTypes.object,
};

export default Banner;
