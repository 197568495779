import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Banner from './Banner';
import Button from '@mui/material/Button';
import Config from '../../utils/config';
import { signOut } from '../../utils/authentication';
import Box from '@mui/material/Box';
const config = new Config();

const ErrorPage = ({ errMsg, instance, invalidToken }) => {
  const onSignOut = () => {
    signOut(instance);
  };

  const renderUnauthorizedMessage = () => {
    return (
      <>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            color: 'error.main',
            display: 'flex',
          }}>
          <ErrorOutlineRoundedIcon aria-hidden={true} sx={{ marginRight: '6px' }} />
          <span>Unauthorized</span>
        </Box>
        <p>Sorry, you are not authorized to access this application.</p>
        <p>
          If you feel this is in error, or would like to gain access, you can inquire in the{' '}
          <a
            href={config.get('FSAT_TEAMS_ROOM_URL')}
            target="_blank"
            title="opens in a new tab or window"
            rel="noopener noreferrer">
            Support Dashboard Team Help channel
          </a>
          .
        </p>
      </>
    );
  };

  const renderErrorMessage = () => {
    return (
      <>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            color: 'error.main',
            display: 'flex',
          }}>
          <ErrorOutlineRoundedIcon aria-hidden={true} sx={{ marginRight: '6px' }} />
          <span>Error</span>
        </Box>
        <p>{errMsg}</p>
        <p>
          If the error is persistent, you can inquire in the{' '}
          <a
            href={config.get('FSAT_TEAMS_ROOM_URL')}
            target="_blank"
            title="opens in a new tab or window"
            rel="noopener noreferrer">
            Support Dashboard Team Help channel
          </a>
          .
        </p>
      </>
    );
  };

  const renderSessionExpired = () => {
    return (
      <>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            display: 'flex',
          }}>
          <span>Your session has expired.</span>
        </Box>
        <Button color="primary" onClick={onSignOut}>
          Sign in again
        </Button>
      </>
    );
  };
  return (
    <>
      <Banner instance={instance} />
      <Container maxWidth="md">
        <Box
          sx={{
            marginTop: 12,
            paddingTop: 4,
            paddingBottom: 3,
            paddingLeft: 4,
          }}>
          {invalidToken
            ? renderSessionExpired()
            : errMsg
            ? renderErrorMessage()
            : renderUnauthorizedMessage()}
        </Box>
      </Container>
    </>
  );
};

ErrorPage.propTypes = {
  invalidToken: PropTypes.bool,
  instance: PropTypes.object,
};

export default ErrorPage;
